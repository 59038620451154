import {
    BindingEngine,
    customElement,
    inject,
    LogManager,
} from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Client } from '../api/client';
import { UserClient } from '../api/user-client';
import { AuthTokenStorage } from '../auth/auth-token-storage';
import { AuthService } from '../auth/auth-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AureliaConfiguration } from 'aurelia-configuration';
import { ModuleConfigClient } from '../api/module-config-client';
import $ from 'jquery';
import './jquery-menu-aim';
import { I18N } from 'aurelia-i18n';
import ReactMenu from './ReactMenu';
import { render, unmountComponentAtNode } from 'react-dom';
import './menu.less';
import React from 'react';
const logger = LogManager.getLogger('menu');
import { Provider } from 'react-redux';
import store from '../store/store';

@customElement('sio-menu')
@inject(
    Client,
    ModuleConfigClient,
    AuthTokenStorage,
    AuthService,
    UserClient,
    EventAggregator,
    AureliaConfiguration,
    BindingEngine,
    I18N,
    Element
)
export class Menu {
    mainMenu = null;
    constructor(
        client,
        moduleConfigClient,
        authTokenStorage,
        authService,
        userClient,
        ea,
        config,
        bindingEngine,
        I18N,
        Element
    ) {
        this.client = client;
        this.moduleConfigClient = moduleConfigClient;
        this.authTokenStorage = authTokenStorage;
        this.authService = authService;
        this.userClient = userClient;
        this.ea = ea;
        this.config = config;
        this.bindingEngine = bindingEngine;
        this.container = $('#menuPopover');
        this.i18n = I18N;
        this.container = Element;
    }

    reactRender() {
        if (this.container) {
            if (this.user && this.config && this.menu) {
                render(
                    <Provider store={store}>
                        <ReactMenu
                            menu={this.menu}
                            user={this.user}
                            config={this.config}
                            client={this.client}
                        />
                    </Provider>,
                    this.container
                );
            }
        }
    }

    attached() {
        console.debug('activate menu');

        if (this.authTokenStorage.isLoggedIn()) {
            this.load();
        }
        this.subscription = this.ea.subscribe(
            'sio_login_change',
            (response) => {
                logger.debug('Login change', response);

                this.menu = null;
                this.user = null;

                if (response.loggedIn) {
                    this.load();
                }
            }
        );

        this.changeSubscription = this.ea.subscribe(
            'sio_form_post_submit',
            (response) => {
                if (response.config.modelId != 'user/user') {
                    return;
                }
                this.userClient.load().then((user) => {
                    this.user = user;
                    this.reactRender();
                });
            }
        );
    }

    detached() {
        this.subscription?.dispose();
        this.changeSubscription?.dispose();
        unmountComponentAtNode(this.container);
    }

    async load() {
        const data = await this.client.get('menu');

        this.menu = {};

        data.forEach((item) => {
            if (item.items) {
                let groups = {};
                let noGroup = [];
                let foundGroup = false;

                item.items.forEach((item) => {
                    if (item.subLabel) {
                        groups[item.subLabel] = groups[item.subLabel] || [];
                        groups[item.subLabel].push(item);
                        foundGroup = true;
                    } else {
                        noGroup.push(item);
                    }
                });

                item.items = { noGroup: noGroup };

                if (foundGroup) {
                    item.items.groups = groups;
                }
            }
        });

        this.menu = data;

        this.user = this.userClient.user;

        this.reactRender();
    }

    async itemsReleaseNote() {
        const container = document.getElementById('MenuPopoverReleaseNote');
        if (container) {
            render(
                <MenuPopoverReleaseNote
                    userId={this.user.id}
                    client={this.client}
                />,
                container
            );
        }
    }
}
